.web3Button {
  background-color: #668AB4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.web3Button:hover {
  background-color: #557a9b;
}

.toggleButton {
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  text-align: right;
  display: flex;
  justify-content: 'center';
  align-items: 'center';
  gap: 4px;
}

.toggleButton:hover {
  text-decoration: underline;
}

.qrContainer {
  text-align: center;
  margin-top: 20px;
}

.downloadButton {
  margin-top: 10px;
  margin-bottom: 5px;
}
